<template>
     <v-container id="user-profile" fluid tag="section" class="grey lighten-5 mb-1 mt-10">
              <v-row justify="center">
                <v-col cols="12" md="10">
                     <v-card>
                        <template v-slot:heading>
                            <div class="text-h3 font-weight-light">
                                Edit {{$store.state.username}} profile
                            </div>
                            <div class="text-subtitle-1 font-weight-light" v-if="isCustomerUser">
                                {{$store.state.userDetails.customerName}}
                            </div>
                            <div class="text-subtitle-1 font-weight-light" v-if="!isCustomerUser">
                                <v-chip v-for="role in $store.state.userDetails.roles" :key="role" > {{role}} </v-chip>
                            </div>
                        </template>
                         <v-form  v-model="valid">
                              <v-container class="py-0">
                                  <v-row>
                                        <v-col cols="12" md="6">
                                             <v-text-field
                                                id="username"
                                                class="green-input"
                                                v-model="usernameval"
                                                label="User Name"
                                                :error-messages="errors"
                                                :rules="[rules.required]"
                                                type="text" />
                                        </v-col>
                                  </v-row>
                                   <v-row>
                                        <v-col cols="12" md="6">
                                             <v-text-field
                                                id="password"
                                                class="green-input"
                                                v-model="form.password"
                                                label="Password"
                                                :rules="[rules.required,rules.password]"
                                                :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                                                @click:append="() => (value = !value)"
                                                :type="value ? 'password' : 'text'" />
                                        </v-col>
                                         <v-col cols="12" md="6">
                                             <v-text-field
                                                id="password"
                                                class="green-input"
                                                label="confirm Password"
                                                :rules="[rules.required,rules.confirmpassword]"
                                                 :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                                                @click:append="() => (value = !value)"
                                                :type="value ? 'password' : 'text'" />
                                        </v-col>
                                   </v-row>
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                id="email"
                                                class="green-input"
                                                v-model="form.email"
                                                label="E-Mail"
                                                :rules="[rules.required,rules.emailRule]"
                                                type="text" />
                                        </v-col>
                                    </v-row>
                                     <v-row>
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                id="telephone"
                                                class="green-input"
                                                v-model="form.telephone"
                                                label="Telephone"
                                                :rules="[rules.required]"
                                                type="text" />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                         <v-col cols="12" md="6">
                                            <v-btn color="success" class="mr-0" :disabled="!valid" @click="updateUserDetails">
                                                Update
                                            </v-btn>
                                         </v-col>
                                    </v-row>
                              </v-container>
                         </v-form>
                     </v-card>
                </v-col>
              </v-row>

               <div class="text-center">
                    <v-snackbar
                    v-model="snackbar"
                    timeout="3000">
                        {{ alertMsg }}
                        <template v-slot:action="{ attrs }">
                            <v-btn
                            color="green"
                            text
                            v-bind="attrs"
                            @click="snackbar = false">
                            Close
                            </v-btn>
                        </template>
                    </v-snackbar>
                </div>

     </v-container>
     <!-- {{$store.state.userId}} -->
</template>

<script>
export default {
    computed: {
        isCustomerUser () {
            return this.$store.getters.customer !='No Customer';
        }
    },
    watch:{
         usernameval: async function(val){
            this.$axios({
                            method: 'get',
                            url: `${this.$baseURL}/Account/UserName`,
                            params:{
                                UserName: val,
                                OriginalUserName:String(this.originalUserName)
                            }
                        })
                        .then((response)=>{
                            if(JSON.parse(response.data.status) == true){
                                response.data.result ? console.log('true') : console.log('false');
                                this.errors = response.data.result ? [] : ['User name already exists']
                            }
                        });
        }
    },
     data(){
         return{
             errors:[],
             valid: true,
             value: String,
             snackbar: false,
             alertMsg:'',
             validUserName:true,
             originalUserName:'',
             usernameval:'',
             rules: {
                required: value => !!value || 'Required Field',
                emailRule:v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) || 'E-mail must be valid',
                confirmpassword:value => value === this.form.password || 'two passwords do not match',
                password: value => {
                    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/;
                    return (
                        pattern.test(value) ||
                        "Min. 8 characters with at least one capital letter, a number and a special character."
                    );
                },

            },
            form:{
                 userId:this.$store.state.userId,
                 username:null,
                 password:null,
                 email:null,
                 telephone:null,
             }
         }
     },
     methods:{
         checkUserName(){
             this.$axios({
                method: 'get',
                url: `${this.$baseURL}/Account/UserName`,
                params:{
                    UserName: String(this.form.username),
                    OriginalUserName:String(this.originalUserName)
                }
            })
             .then((response)=>{
                 if(JSON.parse(response.data.status) == true){

                      this.$nextTick(() => {
                            this.validUserName = JSON.parse(response.data.result)
                        });

                    }
            });
         },
         getUserProfileDetails(){
             console.log('asdadasdasd')
              this.$axios({
                method: 'get',
                url: `${this.$baseURL}/Account/userdetails`,
                params:{
                    UserId: String(this.form.userId)
                }
            })
            .then((response)=>{
                if(JSON.parse(response.data.status) == true){
                    this.usernameval = response.data.userDetails.userName;
                    this.form.username = response.data.userDetails.userName;
                    this.form.email = response.data.userDetails.email;
                    this.form.telephone = response.data.userDetails.telephone;
                    this.form.password = response.data.userDetails.password??'';
                    this.originalUserName = response.data.userDetails.userName;
                }
            });
         },
         updateUserDetails(){
             this.$axios({
                method: 'put',
                url: `${this.$baseURL}/Account/userdetails`,
                headers: {'Content-Type': 'application/json' },
                data: {
                    Id:this.form.userId,
                    UserName:this.form.username,
                    Password:this.form.password,
                    EMail:this.form.email,
                    PhoneNumber:this.form.telephone
                }
            }).then((response)=>{
                    if(JSON.parse(response.data.status) == true){
                        this.alertMsg = "‘User Info updated successfully"
                        this.snackbar = true;
                    }
                });
         }
     },
      mounted(){
        this.getUserProfileDetails();
    }
}
</script>

<style>

</style>